export interface Tag {
	id: number
	name: string
	description?: string
}

export interface AppUser {
	id: number
	name: string
	email: string
	username: string
	role: 'CLIENT' | 'COACH' | 'ADMIN'
	status: 'ACTIVE' | 'INACTIVE'
	user_avatar?: string | null
	createdAt?: string
	updatedAt?: string
	hasSelectedRole: boolean
}

export interface PostData {
	id: number
	title: string
	description: string
	published: boolean
	post_tags: Tag[]
	author: AppUser
	createdAt: string
	post_image: FileList | string | null
	protocolId?: number
	protocol?: Protocol
}

export interface ProtocolItem {
	id: number
	item: string
	quantity: string
	impact?: string
	protocolId: number
	techniqueId?: number
	technique?: TrainingTechnique
}

export interface PostFormProps {
	initialPost?: PostData
	selectedTags?: string[]
}

export interface ProtocolFormProps {
	postId?: number
	programId?: number
	protocolId?: number
	initialProtocol?: Protocol
	onSubmit?: (protocol: Protocol) => void
	onProtocolSaved?: (protocol: Protocol) => void
}

export interface ProtocolItemsTableProps {
	protocolItems: ProtocolItem[]
	setItems: (items: ProtocolItem[]) => void
	setEditItem: (item: ProtocolItem | null) => void
	isEditable?: boolean
}

export interface CreatableMultiSelectProps {
	control: any
	name: string
	placeholder?: string // Optional placeholder
	selectedTags?: string[] // New prop for selected tags
}

export interface MultiSelectProps {
	control: any
	name: string
	placeholder?: string
	options: { value: string; label: string }[]
}

export interface Program {
	id: number
	program_name: string
	goal: string
	duration_in_weeks: number
	program_description?: string
	program_cover: FileList | string | null
	private: boolean
	featured: boolean
	createdAt: string
	updatedAt: string
	enrollments?: ProgramEnrollment[]
}

export interface ProgramFormProps {
	initialProgram?: Program
	onCancel?: () => void
}

export interface Protocol {
	id: number
	protocolId: number
	title: string
	subtitle?: string
	postId?: number
	type: 'WORKOUT' | 'DIET' | 'OTHER'
	programId?: number
	protocolItems: ProtocolItem[]
	createdAt: string
	updatedAt: string
	isChecked?: boolean
	isToday?: boolean
}

export interface ProgramProtocol {
	id: number
	protocolId: number
	programId: number
	order?: number
	protocol: Protocol
	createdAt: string
	updatedAt: string
	isChecked: boolean
	isToday: boolean
}
export interface RegisterUser {
	name: string
	username: string
	email: string
	password: string
	confirmEmail: string
	confirmPassword: string
	role: 'CLIENT' | 'COACH' | 'ADMIN'
	skipVerification: boolean
}

export interface LoginCredentials {
	usernameOrEmail: string
	password: string
}

export interface ProgramEnrollment {
	id: number
	userId: number
	programId: number
	status: 'ACTIVE' | 'INACTIVE' | 'COMPLETED'
	createdAt: string
	updatedAt: string
	user: AppUser
	program: Program
}

export type ProgramUpdateInput = Omit<Program, 'createdAt' | 'updatedAt' | 'enrollments'>

export interface FetchUsersParams {
	role?: 'CLIENT' | 'COACH' | 'ADMIN'
	status?: 'ACTIVE' | 'INACTIVE'
}

export enum Sex {
	MALE = 'MALE',
	FEMALE = 'FEMALE',
}

export enum Objective {
	GAIN_MASS = 'GAIN_MASS',
	LOSE_FAT = 'LOSE_FAT',
	CONDITIONING = 'CONDITIONING',
	IMPROVE_STRENGTH = 'IMPROVE_STRENGTH',
	POSTURE = 'POSTURE',
	OTHER = 'OTHER',
}

export enum ExperienceLevel {
	BEGINNER = 'BEGINNER',
	INTERMEDIATE = 'INTERMEDIATE',
	ADVANCED = 'ADVANCED',
}

export enum ScoreType {
	NEEDS = 'NEEDS',
	WANTS = 'WANTS',
	ATHLETE_CATEGORY = 'ATHLETE_CATEGORY',
}

export enum AthleteCategory {
	MENS_PHYSIQUE = 'MENS_PHYSIQUE',
	WELLNESS = 'WELLNESS',
	'212_POUNDS' = '212',
	OPEN = 'WELLNESS',
	CLASSIC = 'CLASSIC_PHYSIQUE',
}

export enum MuscleGroup {
	CHEST = 'CHEST',
	BACK = 'BACK',
	LEGS = 'LEGS',
	SHOULDERS = 'SHOULDERS',
	ARMS = 'ARMS',
	CORE = 'CORE',
	BICEPS = 'BICEPS',
	TRICEPS = 'TRICEPS',
	QUADS = 'QUADS',
	HAMSTRINGS = 'HAMSTRINGS',
	GLUTES = 'GLUTES',
	TRAPEZIUS = 'TRAPEZIUS',
	FOREARMS = 'FOREARMS',
	CALVES = 'CALVES',
}
export interface MuscleScoreInput {
	muscleGroup: MuscleGroup
	scoreType: ScoreType
	scoreValue: number
}
export interface UserProfile {
	height: number
	weight: number
	sex: Sex
	age: number
	objective: Objective
	experienceLevel: ExperienceLevel
	injuries: string
	competition: boolean
	athleteCategory?: AthleteCategory
	needsMuscleGroups: MuscleGroup[]
	wantsMuscleGroups: MuscleGroup[]
	muscleScores: MuscleScoreInput[]
}
export interface TrainingExercise {
	name: string
	quantity: string
	muscleGroup: MuscleGroup
	impact: string
	advancedTechnique?: string | null
}
export interface TrainingDay {
	order: number
	title: string
	subtitle: string
	exercises: TrainingExercise[]
}
export interface TrainingPlan {
	programName: string
	objective: string
	description: string
	duration_in_weeks: number
	days: TrainingDay[]
}
export interface TrainingTechnique {
	id: number
	name: string
	description?: string
	createdAt: string
	updatedAt: string
}
export interface GenerateTrainingPlanRequest {
	trainingDaysPerWeek: number
	preferredTrainingTime: 'MORNING' | 'AFTERNOON' | 'EVENING'
	sessionDuration: number
	advancedTechniques?: string[]
}
export interface GenerateTrainingPlanResponse {
	message: string
	programId: number
}
